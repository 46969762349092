<template>
  <div>
    <select v-model.number="routeId">
      <option v-for="route in routes" :key="route.id" :value="route.id">{{route.label}}</option>
    </select>
    <div class="list">
      <div v-for="m in list">{{m.email}}</div>
    </div>
  </div>
</template>

<script>
import iField from '../../components/widgets/iField.vue';

export default {
  components: { iField },
  name: "ModelIndex",
  data() {
    return {
      members: [],
      routes: [],
      routeId: 3,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.$api.get("/members?fields=email,mailchimp_status,collectors.route_id,collectors.disabled&expand=collectors").then((j) => {
        this.members = j.data;
      });
      this.$api.get("/routes").then((j) => {
        this.routes = j.data;
      });
    },
  },
  computed: {
    list() {
      if (!this.routeId) return [];

      return this.members.filter(member => {
        // member has ot unsubscribed
        if (member.mailchimp_status !== 'subscribed') return false;

        // has an enabled collector on selected route
        if (!member.collectors.some(col => col.route_id == this.routeId && col.disabled === null)) return false;

        return true;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  background: white;
  padding: 1em;
  margin: 1em 0;
  max-height: 50vh;
  overflow: auto;
}
</style>